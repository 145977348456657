import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <Layout>
      <h1>NOT FOUND</h1>
      <p>This page doesn't exist...</p>
      <Link to="/">Visit Home Page</Link>
    </Layout>
  </>
)

export default NotFoundPage
